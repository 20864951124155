import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout";
import * as ROUTES from "../../constants/routes";
import { compose } from "recompose";
import {
  withAuthorization,
  withActiveSubscription,
} from "../../components/Session";
import PageHeader from "../../components/PageHeader";
import ExerciseLibraryListing from "../../components/ExerciseLibraryListing";
import ExerciseLibraryTags from "../../components/ExerciseLibraryTags";

const ExerciseLibraryIndexPageBase = ({ pageContext, data }) => {
  const [showAll, setShowAll] = React.useState(false);
  let title = null;

  if (pageContext.item) {
    title = {
      breadcrumb: (
        <Link to={ROUTES.EXERCISE_LIBRARY} className="light-link">
          Exercise Demos
        </Link>
      ),
      title: pageContext.item.fieldValue,
    };
  } else {
    title = { title: "Exercise Demos" };
  }

  const posts = data.allMarkdownRemark.edges;
  const postLinks = posts.map((post) => (
    <li key={post.node.fields.slug}>
      <h3 className="title is-size-3">
        {" "}
        <Link to={post.node.fields.slug}>{post.node.frontmatter.title}</Link>
      </h3>
    </li>
  ));
  const allList = <ul className="exerciselist">{postLinks}</ul>;

  return (
    <div className="exercise-library-page">
      <PageHeader {...title} />
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            {pageContext.item === undefined && (
              <>
                {!showAll && (
                  <>
                    <p>
                      Select a category below or{" "}
                      <button
                        className="link"
                        onClick={() => {
                          setShowAll(true);
                        }}
                      >
                        view all exercises
                      </button>
                    </p>
                    <ExerciseLibraryTags />
                  </>
                )}

                {showAll && (
                  <>
                    <button
                      className="link"
                      onClick={() => {
                        setShowAll(false);
                      }}
                    >
                      Browse exercises by category
                    </button>
                    {allList}
                  </>
                )}
              </>
            )}
            {pageContext.item && (
              <ExerciseLibraryListing pageContext={pageContext} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;

const ExerciseLibraryIndexPage = compose(
  withAuthorization(condition),
  withActiveSubscription
)(ExerciseLibraryIndexPageBase);

const ExerciseLibraryIndexPageFinal = ({ pageContext }) => (
  <StaticQuery
    query={graphql`
      query ExerciseLibraryQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: {
            frontmatter: { templateKey: { eq: "exercise-library-post" } }
          }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <Layout>
          <ExerciseLibraryIndexPage pageContext={pageContext} data={data} />
        </Layout>
      );
    }}
  />
);

export default ExerciseLibraryIndexPageFinal;
