import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import balance from "../img/exlib/exlib-balance.jpg";
import core from "../img/exlib/exlib-core.jpg";
import cardio from "../img/exlib/exlib-cardio.jpg";
import stretching from "../img/exlib/exlib-stretching.jpg";
import lowerBody from "../img/exlib/exlib-lower-body.jpg";
import upperBody from "../img/exlib/exlib-upper-body.jpg";

class ExerciseLibraryTags extends React.Component {
  render() {
    const tags = this.props.data.allMarkdownRemark.group;
    const buttons = tags.map((post) => {
      let image = null;
      let alt = null;
      if (post.fieldValue.toLowerCase() === "balance") {
        image = balance;
        alt = "Balance";
      } else if (post.fieldValue.toLowerCase() === "core") {
        image = core;
        alt = "Core";
      } else if (post.fieldValue.toLowerCase() === "cardio") {
        image = cardio;
        alt = "Cardio";
      } else if (post.fieldValue.toLowerCase() === "stretching") {
        image = stretching;
        alt = "Stretching";
      } else if (post.fieldValue.toLowerCase() === "lower body") {
        image = lowerBody;
        alt = "Lower Body";
      } else if (post.fieldValue.toLowerCase() === "upper body") {
        image = upperBody;
        alt = "Upper Body";
      }

      return (
        <div key={post.fieldValue} className="column is-4">
          <div className="card">
            <div className="card-image">
              <figure className="image is-square">
                <img src={image} alt={alt} />
              </figure>
            </div>
            <div className="card-content">
              <div className="media">
                <div className="media-content">
                  <p className="title is-4">{post.fieldValue}</p>
                  <p className="subtitle is-6">
                    {post.totalCount} video
                    {post.totalCount > 1 && "s"}
                  </p>
                </div>
              </div>

              <footer className="card-footer" style={{ paddingTop: "1.5rem" }}>
                <p className="card-footer-item ">
                  <Link
                    to={`/exercise-library/tag/${post.fieldValue}`}
                    className="dark-link"
                  >
                    Browse {post.fieldValue}
                  </Link>
                </p>
              </footer>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div style={{ marginBottom: "200px" }}>
        <div className="columns is-multiline has-text-centered">{buttons}</div>
      </div>
    );
  }
}

ExerciseLibraryTags.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.array,
    }),
  }),
};

const ExerciseLibraryTagsFinal = () => (
  <StaticQuery
    query={graphql`
      query ExerciseLibraryTagsQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: {
            frontmatter: { templateKey: { eq: "exercise-library-post" } }
          }
        ) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `}
    render={(data) => <ExerciseLibraryTags data={data} />}
  />
);

export default ExerciseLibraryTagsFinal;
