import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

class ExerciseLibraryListing extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges;
    const postLinks = posts.map((post) => (
      <li key={post.node.fields.slug}>
        <h3 className="title is-size-3">
          {" "}
          <Link to={post.node.fields.slug}>{post.node.frontmatter.title}</Link>
        </h3>
      </li>
    ));

    return <ul className="exerciselist">{postLinks}</ul>;
  }
}

ExerciseLibraryListing.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

const ExerciseLibraryListingFinal = ({
  pageContext: {
    itemList: { data },
  },
}) => <ExerciseLibraryListing data={data} />;

export default ExerciseLibraryListingFinal;
